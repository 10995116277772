import React from "react"
import { PageCenter } from "../../../components/elements/wrappers"
import { DefaultHeader, PortfolioCard } from "../../../components/elements"
import { getImage } from "gatsby-plugin-image"
import { Row, Col } from "react-bootstrap"
import { useStaticQuery, graphql } from "gatsby"

import { Page } from "../../../components/elements"

export default function ProjectPortfolioPage() {
    const { allFile } = useStaticQuery(
        graphql`
            query {
                allFile(
                    filter: {
                        extension: { regex: "/jpg|png/" }
                        absolutePath: {
                            regex: "/EGI_Project_Portfolio_Cover|EGI_Report_Listing_Cover/"
                        }
                    }
                ) {
                    edges {
                        node {
                            base
                            childImageSharp {
                                gatsbyImageData
                            }
                        }
                    }
                }
            }
        `,
    )

    let images = {}
    allFile.edges.forEach(e => {
        images[e.node.base] = getImage(e.node)
    })

    return (
        <Page
            title="Project Portfolio & Reports"
            description="Download our Project Portfolio for scope of work and deliverables related to our current projects. The EGI Project Reports book is a catalogue of 850+ EGI projects and and can be downloaded as an efficient reference for the geoscience reports and data available to all members via EGIconnect – EGI’s Online Global Database."
        >
            <PageCenter>
                <DefaultHeader
                    title="Project Portfolio & Reports"
                    subtitle="Download our Project Portfolio for scope of work and deliverables related to our current projects. The EGI Project Reports book is a catalogue of 850+ EGI projects and and can be downloaded as an efficient reference for the geoscience reports and data available to all members via EGIconnect – EGI’s Online Global Database."
                    type="small"
                />
                <Row className="pb-5">
                    <Col>
                        <PortfolioCard
                            title="Project Portfolio"
                            image={images["EGI_Project_Portfolio_Cover.jpg"]}
                            href={"/media/EGI_Project_Portfolio_e.pdf"}
                            className="ml-auto"
                        />
                    </Col>
                    <Col>
                        <PortfolioCard
                            title="EGI Project Reports"
                            image={images["EGI_Report_Listing_Cover.jpg"]}
                            href="#"
                            className="mr-auto"
                        />
                    </Col>
                    {/* href="/media/EGI_Report_Listing.pdf" */}
                </Row>
            </PageCenter>
        </Page>
    )
}
